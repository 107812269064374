import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TeamLeaderRoutingModule } from './team-leader-routing.module';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { LayoutModule } from '../layout/layout.module';
import { TeamLeaderComponent } from './team-leader.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TeamLeaderProvider } from './Providers/TeamLeaderProvider';
import { TeamLeaderDataProvider } from './Providers/TeamLeaderDataProvider';
import { NotfoundComponent } from './Components/notfound/notfound.component';
import { AlertService } from '../Providers/alertProvider';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    DashboardComponent,
    TeamLeaderComponent,
    NotfoundComponent,
  ],
  imports: [
    CommonModule,
    TeamLeaderRoutingModule,
    LayoutModule,
    NgxUiLoaderModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
  ],
  providers: [
    TeamLeaderDataProvider,
    TeamLeaderProvider,
    AlertService,
    DatePipe
  ]
})
export class TeamLeaderModule { }
