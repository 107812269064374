import { Injectable } from "@angular/core";
import { ProgramModel, CampaignModel } from "../../models/ProgramModel";
import {
  imageModel,
  PosmAuditTransactionsModel,
} from "../Models/PosmAuditTransactionsModel";
import { TeamLeaderService } from "../Service/team-leader.service";
import { BehaviorSubject } from "rxjs";
import { UserServiceService } from "../../Service/user-service.service";
import { UserModel, Users } from "../../models/UserModel";
import { AssignedUsersCount } from "../Models/PosmAuditAssignedRecords";
import { AlertService } from "../../Providers/alertProvider";
import { WeekEnttries, PictureTaskModel } from "../Models/WeekEntries";
import { InvoiceModel } from "../../agents/Models/Invoice";
import { MysteryShopperRecord } from "../Models/MysteryShopper";
import {
  PosmConfigModel,
  PosmAuditModel,
} from "../../agents/Models/posm-config";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class TeamLeaderProvider {
  programList: ProgramModel[];
  selectedProgram: BehaviorSubject<string> = new BehaviorSubject("");
  posmAuditTransaction: PosmAuditTransactionsModel[];
  posmAuditUnassignedList: PosmAuditTransactionsModel[];
  posmAuditAssignedPendingList: PosmAuditTransactionsModel[];
  posmAuditRejectedList: PosmAuditTransactionsModel[];
  posmAuditAssignedProcessedList: PosmAuditTransactionsModel[];
  coolerAuditTransaction: PosmAuditTransactionsModel[];
  coolerAuditUnassignedList: PosmAuditTransactionsModel[];
  coolerAuditAssignedPendingList: PosmAuditTransactionsModel[];
  coolerAuditRejectedList: PosmAuditTransactionsModel[];
  coolerAuditAssignedProcessedList: PosmAuditTransactionsModel[];
  engagementposmAuditTransaction: PosmAuditTransactionsModel[];
  engagementposmAuditUnassignedList: PosmAuditTransactionsModel[];
  engagementposmAuditAssignedPendingList: PosmAuditTransactionsModel[];
  engagementposmAuditRejectedList: PosmAuditTransactionsModel[];
  engagementposmAuditAssignedProcessedList: PosmAuditTransactionsModel[];
  totalEnagement: any[];
  Users: Users[];
  posmAssignedCounts: AssignedUsersCount[];
  coolerAssignedCounts: AssignedUsersCount[];
  posmEngagementAssignedCounts: AssignedUsersCount[];
  mysteryAssignedCounts: AssignedUsersCount[];
  contestAssignedCounts: AssignedUsersCount[];
  pictureAssignedCounts: AssignedUsersCount[];
  pictureTaskRecords: PictureTaskModel[];
  pictureTaskRecordsUnassignedList: PictureTaskModel[];
  pictureTaskRecordsAssignedPendingList: PictureTaskModel[];
  pictureTaskRecordsRejectedList: PictureTaskModel[];
  pictureTaskRecordsAssignedProcessedList: PictureTaskModel[];
  weekEntries: WeekEnttries[];
  WeekEntriesUnassignedList: WeekEnttries[];
  WeekEntriesAssignedPendingList: WeekEnttries[];
  WeekEntriesAssignedProcessedList: WeekEnttries[];
  WeekEntriesRejectedList: WeekEnttries[];
  invoiceRecords: InvoiceModel[];
  CampaignList: CampaignModel[];
  invoiceRecordsUnassignedList: InvoiceModel[];
  invoiceRecordsAssignedPendingList: InvoiceModel[];
  invoiceRecordsAssignedProcessedList: InvoiceModel[];
  invoiceRecordsRejectedList: InvoiceModel[];
  mysteryShopperRecords: MysteryShopperRecord[];
  mysteryShopperUnassignedList: MysteryShopperRecord[];
  mysteryShopperAssignedPendingList: MysteryShopperRecord[];
  mysteryShopperAssignedProcessedList: MysteryShopperRecord[];
  mysteryShopperRejectedList: MysteryShopperRecord[];
  posmConfigList: PosmConfigModel[];
  posmAuditList: PosmAuditModel[];
  posmAuditType: PosmAuditModel[];
  enagementRecords: any[];
  enagementRecords1: any[];

  constructor(
    public teamLeaderService: TeamLeaderService,
    public userService: UserServiceService,
    public alertService: AlertService,
    private toastr: ToastrService
  ) {}

  getAllProgamData(): void {
    this.teamLeaderService.getAllPrograms().subscribe(
      (response: any) => {
        this.programList = response.data;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPosmAuditTypes() {
    this.teamLeaderService.posmAuditType().subscribe(
      (response) => {
        this.posmAuditType = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  coolerDropdown = [
    { id: 1, name: "Cooler is Pure & As Per Planogram!" },
    { id: 2, name: "Cooler is Pure (but not as per planogram)" },
    { id: 3, name: "Sorry, Your Cooler is Not Pure" },
    {
      id: 4,
      name: "Sorry, Your Cooler is Not Pure (Non AB InBev Product Detected)",
    },
  ];

  getAllCampaignData(): void {
    this.teamLeaderService.GetCampaigns().subscribe(
      (response: any) => {
        this.CampaignList = response.data;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllAgents(): void {
    this.userService.getUsers().subscribe(
      (response) => {
        this.Users = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllCoolerPosmAuditTransactionData(): void {
    this.teamLeaderService.coolerAuditTransactions().subscribe(
      (response: any) => {
        this.coolerAuditTransaction = response;
        if (this.coolerAuditTransaction.length > 0) {
          this.coolerAuditTransaction.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "Not Assigned";
            } else if (p.internal_status == 1) {
              p.statusString = "Assigned";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }

            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }

            if (p.reason == 1) {
              p.rejectedReasonString = "Blurred Image Submitted";
            }

            if (p.reason == 2) {
              p.rejectedReasonString = "Incorrect Image Submitted";
            }

            if (p.reason == 3) {
              p.rejectedReasonString = "Invalid bill type";
            }

            if (p.reason == 4) {
              p.rejectedReasonString = "Product not found on the bill";
            }

            if (p.agent_comments != null) {
              let data = this.coolerDropdown.find((c) =>
                c.name.match(p.agent_comments)
              );
              if (typeof data != "undefined") {
                p.comment_selected = this.coolerDropdown.find(
                  (c) => c.id === data.id
                ).id;
                console.log(data);
                console.log(p.comment_selected);
              }
            }

            if (p.ml_computation_flag == 0) {
              p.mlStatusString = "No";
            } else if (p.ml_computation_flag == 1) {
              p.mlStatusString = "Yes";
            } else {
              p.mlStatusString = "N/A";
            }

            p.images = [];

            if (p.image_url != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.image_url;
              p.images.push(imageData);
            }

            if (p.denominator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.denominator_image;
              p.images.push(imageData);
            }

            if (p.numerator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.numerator_image;
              p.images.push(imageData);
            }

            if (p.xray_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.xray_image;
              p.images.push(imageData);
            }

            if (p.posm_ml_output_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.posm_ml_output_image;
              p.images.push(imageData);
            }
          });

          /*** COOLER TYPE ******/
          this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
            (p) => p.posm_type == 3
          );
          this.coolerAuditUnassignedList = this.coolerAuditTransaction.filter(
            (p) => p.status == 3 && p.internal_status == 0 && p.posm_type == 3
          );
          this.coolerAuditAssignedPendingList = this.coolerAuditTransaction.filter(
            (p) => p.status == 3 && p.internal_status == 1 && p.posm_type == 3
          );
          this.coolerAuditAssignedProcessedList = this.coolerAuditTransaction.filter(
            (p) => p.status == 1 && p.internal_status == 2 && p.posm_type == 3
          );
          this.coolerAuditRejectedList = this.coolerAuditTransaction.filter(
            (p) => p.status == 2 && p.internal_status == 3 && p.posm_type == 3
          );
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllPosmAuditTransactionData(): void {
    this.teamLeaderService.PosmAuditTransactions().subscribe(
      (response: any) => {
        this.posmAuditTransaction = response;
        if (this.posmAuditTransaction.length > 0) {
          this.posmAuditTransaction.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "Not Assigned";
            } else if (p.internal_status == 1) {
              p.statusString = "Assigned";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }

            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }

            if (p.reason == 1) {
              p.rejectedReasonString = "Invalid Picture";
            }

            if (p.reason == 2) {
              p.rejectedReasonString = "Poor image quality of the picture";
            }

            if (p.reason == 3) {
              p.rejectedReasonString = "Invalid bill type";
            }

            if (p.reason == 4) {
              p.rejectedReasonString = "Product not found on the bill";
            }

            if (p.ml_computation_flag == 0) {
              p.mlStatusString = "No";
            } else if (p.ml_computation_flag == 1) {
              p.mlStatusString = "Yes";
            } else {
              p.mlStatusString = "N/A";
            }

            p.images = [];

            if (p.image_url != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.image_url;
              p.images.push(imageData);
            }

            if (p.denominator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.denominator_image;
              p.images.push(imageData);
            }

            if (p.numerator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.numerator_image;
              p.images.push(imageData);
            }

            if (p.xray_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.xray_image;
              p.images.push(imageData);
            }

            if (p.posm_ml_output_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.posm_ml_output_image;
              p.images.push(imageData);
            }
          });

          this.posmAuditTransaction = this.posmAuditTransaction.filter(
            (p) =>
              p.posm_type == 2 && p.campaign_id == null && p.task_id == null
          );
          console.log(this.posmAuditTransaction.length);

          this.posmAuditUnassignedList = this.posmAuditTransaction.filter(
            (p) =>
              p.status == 3 &&
              p.internal_status == 0 &&
              p.posm_type == 2 &&
              p.campaign_id == null &&
              p.task_id == null
          );
          this.posmAuditAssignedPendingList = this.posmAuditTransaction.filter(
            (p) =>
              p.status == 3 &&
              p.internal_status == 1 &&
              p.posm_type == 2 &&
              p.campaign_id == null &&
              p.task_id == null
          );
          this.posmAuditAssignedProcessedList = this.posmAuditTransaction.filter(
            (p) =>
              p.status == 1 &&
              p.internal_status == 2 &&
              p.posm_type == 2 &&
              p.campaign_id == null &&
              p.task_id == null
          );
          this.posmAuditRejectedList = this.posmAuditTransaction.filter(
            (p) =>
              p.status == 2 &&
              p.internal_status == 3 &&
              p.posm_type == 2 &&
              p.campaign_id == null &&
              p.task_id == null
          );
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllEngagementPosmAuditTransactionData(): void {
    this.teamLeaderService.posmEngagementAuditTransactions().subscribe(
      (response: any) => {
        this.engagementposmAuditTransaction = response;
        if (this.engagementposmAuditTransaction.length > 0) {
          this.engagementposmAuditTransaction.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "Not Assigned";
            } else if (p.internal_status == 1) {
              p.statusString = "Assigned";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }
            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }
            if (p.reason == 1) {
              p.rejectedReasonString = "Blurred Image Submitted";
            }

            if (p.reason == 2) {
              p.rejectedReasonString = "Incorrect Image Submitted";
            }

            if (p.reason == 3) {
              p.rejectedReasonString = "Invalid bill type";
            }

            if (p.reason == 4) {
              p.rejectedReasonString = "Product not found on the bill";
            }

            if (p.ml_computation_flag == 0) {
              p.mlStatusString = "No";
            } else if (p.ml_computation_flag == 1) {
              p.mlStatusString = "Yes";
            } else {
              p.mlStatusString = "N/A";
            }

            p.images = [];

            if (p.image_url != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.image_url;
              p.images.push(imageData);
            }

            if (p.denominator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.denominator_image;
              p.images.push(imageData);
            }

            if (p.numerator_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.numerator_image;
              p.images.push(imageData);
            }

            if (p.xray_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.xray_image;
              p.images.push(imageData);
            }

            if (p.posm_ml_output_image != null) {
              let imageData: imageModel = new imageModel();
              imageData.image = p.posm_ml_output_image;
              p.images.push(imageData);
            }
          });

          /*** ENGAGEMENT POSM TYPE ******/

          this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
            (p) =>
              p.posm_type == 2 && p.campaign_id != null && p.task_id != null
          );
          this.totalEnagement = this.engagementposmAuditTransaction;
          this.engagementposmAuditUnassignedList = this.engagementposmAuditTransaction.filter(
            (p) =>
              p.status == 3 &&
              p.internal_status == 0 &&
              p.posm_type == 2 &&
              p.campaign_id != null &&
              p.task_id != null
          );
          this.engagementposmAuditAssignedPendingList = this.engagementposmAuditTransaction.filter(
            (p) =>
              p.status == 3 &&
              p.internal_status == 1 &&
              p.posm_type == 2 &&
              p.campaign_id != null &&
              p.task_id != null
          );
          this.engagementposmAuditAssignedProcessedList = this.engagementposmAuditTransaction.filter(
            (p) =>
              p.status == 1 &&
              p.internal_status == 2 &&
              p.posm_type == 2 &&
              p.campaign_id != null &&
              p.task_id != null
          );
          this.engagementposmAuditRejectedList = this.engagementposmAuditTransaction.filter(
            (p) =>
              p.status == 2 &&
              p.internal_status == 3 &&
              p.posm_type == 2 &&
              p.campaign_id != null &&
              p.task_id != null
          );
          this.enagementRecords = this.engagementposmAuditTransaction;
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  reassignToAgent(reassignIds, reassignedUser, category) {
    this.teamLeaderService
      .ReAssignedRecords(reassignIds, reassignedUser, category)
      .subscribe(
        (response: any) => {
          if (category == 1) {
            this.posmAuditTransaction = response;
            if (this.posmAuditTransaction.length > 0) {
              this.posmAuditTransaction.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                p.checked = false;

                if (p.reason == 1) {
                  p.rejectedReasonString = "Blurred Image Submitted";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Incorrect Image Submitted";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }

                p.images = [];

                if (p.agent_comments != null) {
                  let data = this.coolerDropdown.find((c) =>
                    c.name.match(p.agent_comments)
                  );
                  if (typeof data != "undefined") {
                    p.comment_selected = this.coolerDropdown.find(
                      (c) => c.id == data.id
                    ).id;
                    console.log(data);
                    console.log(p.comment_selected);
                  }
                }

                if (p.image_url != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.image_url;
                  p.images.push(imageData);
                }

                if (p.denominator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.denominator_image;
                  p.images.push(imageData);
                }

                if (p.numerator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.numerator_image;
                  p.images.push(imageData);
                }

                if (p.xray_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.xray_image;
                  p.images.push(imageData);
                }

                if (p.posm_ml_output_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.posm_ml_output_image;
                  p.images.push(imageData);
                }
              });

              this.posmAuditUnassignedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 0 && p.posm_type == 2
              );
              this.posmAuditAssignedPendingList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 1 && p.posm_type == 2
              );
              this.posmAuditAssignedProcessedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 1 && p.internal_status == 2 && p.posm_type == 2
              );
              this.posmAuditRejectedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 2 && p.internal_status == 3 && p.posm_type == 2
              );
              this.posmAuditAssignedPendingList.forEach((p) => {
                p.checked = false;
              });
            }
          } else if (category == 2) {
            this.weekEntries = response;
            if (this.weekEntries.length) {
              this.weekEntries.forEach((e) => {
                e.isConfirmed = false;
                e.isShortlisted = false;
                e.isRejected = false;
                e.notShortlist = false;
                if (e.moderator_internal_status == 9) {
                  e.isConfirmed = true;
                }

                if (e.moderator_internal_status == 4) {
                  e.isShortlisted = true;
                }

                if (e.status == 2) {
                  e.isHolded = true;
                }

                if (e.moderator_internal_status == 6) {
                  e.isRejected = true;
                }

                e.isPDF = false;

                if (e.data_path) {
                  let path = e.data_path.split(".");

                  if (path[path.length - 1] == "pdf") {
                    e.isPDF = true;
                  }
                }

                let sumRating = 0;
                e.rating.forEach((r) => {
                  sumRating += r.rating;
                });
                e.ratingvalue = sumRating;
              });

              this.WeekEntriesUnassignedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 0
              );
              this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 2
              );
              this.WeekEntriesRejectedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 3
              );
              this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 1
              );
            }
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  reassignCoolerToAgent(reassignIds, reassignedUser, category) {
    this.teamLeaderService
      .ReAssignedRecords(reassignIds, reassignedUser, category)
      .subscribe(
        (response: any) => {
          if (category == 6) {
            this.coolerAuditTransaction = response;
            if (this.posmAuditTransaction.length > 0) {
              this.posmAuditTransaction.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                if (p.ml_computation_flag == 0) {
                  p.mlStatusString = "No";
                } else if (p.ml_computation_flag == 1) {
                  p.mlStatusString = "Yes";
                } else {
                  p.mlStatusString = "N/A";
                }

                p.images = [];

                if (p.image_url != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.image_url;
                  p.images.push(imageData);
                }

                if (p.denominator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.denominator_image;
                  p.images.push(imageData);
                }

                if (p.numerator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.numerator_image;
                  p.images.push(imageData);
                }

                if (p.xray_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.xray_image;
                  p.images.push(imageData);
                }

                if (p.posm_ml_output_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.posm_ml_output_image;
                  p.images.push(imageData);
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid Picture";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the picture";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }

                p.checked = false;
              });

              this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
                (p) => p.posm_type == 3
              );
              this.coolerAuditUnassignedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 0 && p.posm_type == 3
              );
              this.coolerAuditAssignedPendingList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 1 && p.posm_type == 3
              );
              this.coolerAuditAssignedProcessedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 1 && p.internal_status == 2 && p.posm_type == 3
              );
              this.coolerAuditRejectedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 2 && p.internal_status == 3 && p.posm_type == 3
              );
              this.coolerAuditAssignedPendingList.forEach((p) => {
                p.checked = false;
              });
            }
          } else if (category == 2) {
            this.weekEntries = response;
            if (this.weekEntries.length) {
              this.weekEntries.forEach((e) => {
                e.isConfirmed = false;
                e.isShortlisted = false;
                e.isRejected = false;
                e.notShortlist = false;
                if (e.moderator_internal_status == 9) {
                  e.isConfirmed = true;
                }

                if (e.moderator_internal_status == 4) {
                  e.isShortlisted = true;
                }

                if (e.status == 2) {
                  e.isHolded = true;
                }

                if (e.moderator_internal_status == 6) {
                  e.isRejected = true;
                }

                e.isPDF = false;

                if (e.data_path) {
                  let path = e.data_path.split(".");

                  if (path[path.length - 1] == "pdf") {
                    e.isPDF = true;
                  }
                }

                let sumRating = 0;
                e.rating.forEach((r) => {
                  sumRating += r.rating;
                });
                e.ratingvalue = sumRating;
              });

              this.WeekEntriesUnassignedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 0
              );
              this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 2
              );
              this.WeekEntriesRejectedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 3
              );
              this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 1
              );
            }
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  getAllProgamCampaignPOSMWithOutDate(program_id, campaign_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) =>
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
    this.posmAuditUnassignedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.posmAuditAssignedPendingList = this.posmAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.posmAuditAssignedProcessedList = this.posmAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.posmAuditRejectedList = this.posmAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
  }

  getAllProgamCampaignPOSMEngagementWithOutDate(program_id, campaign_id): void {
    this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
      (p) =>
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
    this.engagementposmAuditUnassignedList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.engagementposmAuditAssignedPendingList = this.engagementposmAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.engagementposmAuditAssignedProcessedList = this.engagementposmAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
    this.engagementposmAuditRejectedList = this.engagementposmAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_type == 2 &&
        p.posm_audit_typeid == parseInt(campaign_id) &&
        p.program_id == program_id
    );
  }

  getAllProgamCampaignCoolerWithOutDate(program_id, campaign_id): void {
    this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id &&
        p.posm_type == 3
    );
    this.coolerAuditUnassignedList = this.coolerAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_type == 3 &&
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id
    );
    this.coolerAuditAssignedPendingList = this.coolerAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_type == 3 &&
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id
    );
    this.coolerAuditAssignedProcessedList = this.coolerAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_type == 3 &&
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id
    );
    this.coolerAuditRejectedList = this.coolerAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_type == 3 &&
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id
    );
  }

  getAllProgamCampaignPictureWithOutDate(program_id, campaign_id): void {
    this.pictureTaskRecords = this.pictureTaskRecords.filter(
      (p) =>
        p.program_id == program_id &&
        p.campaign_id == campaign_id &&
        p.status == 1
    );
    this.pictureTaskRecordsUnassignedList = this.pictureTaskRecordsUnassignedList.filter(
      (p) =>
        p.internal_status == 0 &&
        p.program_id == program_id &&
        p.campaign_id == campaign_id
    );
    this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecordsAssignedPendingList.filter(
      (p) =>
        p.internal_status == 1 &&
        p.program_id == program_id &&
        p.campaign_id == campaign_id
    );
    this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecordsAssignedProcessedList.filter(
      (p) =>
        p.internal_status == 2 &&
        p.program_id == program_id &&
        p.campaign_id == campaign_id
    );
    this.pictureTaskRecordsRejectedList = this.pictureTaskRecordsRejectedList.filter(
      (p) =>
        p.internal_status == 3 &&
        p.program_id == program_id &&
        p.campaign_id == campaign_id
    );
  }

  getAllProgamCampaignPOSM(program_id, campaign_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditUnassignedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditAssignedPendingList = this.posmAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditAssignedProcessedList = this.posmAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditRejectedList = this.posmAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
  }

  getAllProgamCampaignPicture(program_id, campaign_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.pictureTaskRecords = this.pictureTaskRecords.filter(
      (p) =>
        p.program_id == program_id &&
        p.campaign_id == campaign_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.status == 1
    );
    this.pictureTaskRecordsUnassignedList = this.pictureTaskRecordsUnassignedList.filter(
      (p) =>
        p.internal_status == 0 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecordsAssignedPendingList.filter(
      (p) =>
        p.internal_status == 1 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecordsAssignedProcessedList.filter(
      (p) =>
        p.internal_status == 2 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsRejectedList = this.pictureTaskRecordsRejectedList.filter(
      (p) =>
        p.internal_status == 3 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
  }

  getAllProgamCampaignCooler(program_id, campaign_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditUnassignedList = this.coolerAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditAssignedPendingList = this.coolerAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditAssignedProcessedList = this.coolerAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditRejectedList = this.coolerAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
  }

  getAllProgamCampaignEnagementPOSM(
    program_id,
    campaign_id,
    date1,
    date2
  ): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        p.posm_audit_typeid == campaign_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditUnassignedList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditAssignedPendingList = this.engagementposmAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditAssignedProcessedList = this.engagementposmAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditRejectedList = this.engagementposmAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.posm_audit_typeid == campaign_id &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
  }

  getAllProgamPOSMDate(program_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditUnassignedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditAssignedPendingList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditAssignedProcessedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.posmAuditRejectedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
  }

  getAllProgamCoolerDate(program_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditUnassignedList = this.coolerAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditAssignedPendingList = this.coolerAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditAssignedProcessedList = this.coolerAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
    this.coolerAuditRejectedList = this.coolerAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 3
    );
  }

  getAllProgamPictureDate(program_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.pictureTaskRecords = this.pictureTaskRecords.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.status == 1
    );
    this.pictureTaskRecords = this.pictureTaskRecords.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsUnassignedList = this.pictureTaskRecordsUnassignedList.filter(
      (p) =>
        p.internal_status == 0 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecordsAssignedPendingList.filter(
      (p) =>
        p.internal_status == 1 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecordsAssignedProcessedList.filter(
      (p) =>
        p.internal_status == 2 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
    this.pictureTaskRecordsRejectedList = this.pictureTaskRecordsRejectedList.filter(
      (p) =>
        p.internal_status == 3 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2
    );
  }

  getAllProgamEnagementPOSMDate(program_id, date1, date2): void {
    let tempDate = new Date(date2);
    date2 = tempDate.setDate(tempDate.getDate() + 1);
    this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
      (p) =>
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditUnassignedList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditAssignedPendingList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditAssignedProcessedList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
    this.engagementposmAuditRejectedList = this.engagementposmAuditUnassignedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.program_id == program_id &&
        new Date(p.created_date) >= new Date(date1) &&
        new Date(p.created_date) <= date2 &&
        p.posm_type == 2
    );
  }

  getAllProgamCampaignContest(program_id, campaign_id): void {
    this.weekEntries = this.weekEntries.filter(
      (p) => p.program_id == program_id && p.campaign_id == campaign_id
    );
    this.WeekEntriesUnassignedList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 0 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id
    );
    this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 1 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id
    );
    this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
      (p) =>
        p.status == 2 &&
        p.moderator_internal_status == 2 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id
    );
    this.WeekEntriesRejectedList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 3 &&
        p.campaign_id == campaign_id &&
        p.program_id == program_id
    );
  }

  getAllProgamPOSM(program_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) => p.program_id == program_id && p.posm_type == 2
    );
    this.posmAuditUnassignedList = this.posmAuditUnassignedList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
    this.posmAuditAssignedPendingList = this.posmAuditAssignedPendingList.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
    this.posmAuditAssignedProcessedList = this.posmAuditAssignedProcessedList.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
    this.posmAuditRejectedList = this.posmAuditRejectedList.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.program_id == program_id &&
        p.posm_type == 2
    );
  }

  getAllProgamPicture(program_id): void {
    this.pictureTaskRecords = this.pictureTaskRecords.filter(
      (p) => p.program_id == program_id && p.status == 1
    );
    this.pictureTaskRecordsUnassignedList = this.pictureTaskRecordsUnassignedList.filter(
      (p) =>
        p.status == 1 && p.internal_status == 0 && p.program_id == program_id
    );
    this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecordsAssignedPendingList.filter(
      (p) => p.internal_status == 1 && p.program_id == program_id
    );
    this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecordsAssignedProcessedList.filter(
      (p) => p.internal_status == 2 && p.program_id == program_id
    );
    this.pictureTaskRecordsRejectedList = this.pictureTaskRecordsRejectedList.filter(
      (p) => p.internal_status == 3 && p.program_id == program_id
    );
  }

  getAllProgamCooler(program_id): void {
    this.posmAuditTransaction = this.posmAuditTransaction.filter(
      (p) => p.program_id == program_id && p.posm_type == 3
    );
    this.posmAuditUnassignedList = this.posmAuditTransaction.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 0 &&
        p.program_id == program_id &&
        p.posm_type == 3
    );
    this.posmAuditAssignedPendingList = this.posmAuditTransaction.filter(
      (p) =>
        p.status == 3 &&
        p.internal_status == 1 &&
        p.program_id == program_id &&
        p.posm_type == 3
    );
    this.posmAuditAssignedProcessedList = this.posmAuditTransaction.filter(
      (p) =>
        p.status == 1 &&
        p.internal_status == 2 &&
        p.program_id == program_id &&
        p.posm_type == 3
    );
    this.posmAuditRejectedList = this.posmAuditTransaction.filter(
      (p) =>
        p.status == 2 &&
        p.internal_status == 3 &&
        p.program_id == program_id &&
        p.posm_type == 3
    );
  }

  getAllProgamMystery(program_id): void {
    this.mysteryShopperRecords = this.mysteryShopperRecords.filter(
      (p) => p.program_id == program_id
    );
    this.mysteryShopperUnassignedList = this.mysteryShopperRecords.filter(
      (p) =>
        p.status == 0 && p.internal_status == 0 && p.program_id == program_id
    );
    this.mysteryShopperAssignedPendingList = this.mysteryShopperRecords.filter(
      (p) =>
        p.status == 0 && p.internal_status == 1 && p.program_id == program_id
    );
    this.mysteryShopperAssignedProcessedList = this.mysteryShopperRecords.filter(
      (p) =>
        p.status == 1 && p.internal_status == 2 && p.program_id == program_id
    );
    this.mysteryShopperRejectedList = this.mysteryShopperRecords.filter(
      (p) =>
        p.status == 0 && p.internal_status == 3 && p.program_id == program_id
    );
  }

  getAllProgamContest(program_id): void {
    this.weekEntries = this.weekEntries.filter(
      (p) => p.program_id == program_id
    );
    this.WeekEntriesUnassignedList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 0 &&
        p.program_id == program_id
    );
    this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 1 &&
        p.program_id == program_id
    );
    this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
      (p) =>
        p.status == 2 &&
        p.moderator_internal_status == 2 &&
        p.program_id == program_id
    );
    this.WeekEntriesRejectedList = this.weekEntries.filter(
      (p) =>
        p.status == 3 &&
        p.moderator_internal_status == 3 &&
        p.program_id == program_id
    );
  }

  getPosmBulkAssignedData(): void {
    this.teamLeaderService.PosmBulkAssignedRecords().subscribe(
      (response) => {
        this.posmAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPictureBulkAssignedData(): void {
    this.teamLeaderService.PictureBulkAssignedRecords().subscribe(
      (response) => {
        this.pictureAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPosmCoolerBulkAssignedData(): void {
    this.teamLeaderService.PosmcoolerBulkAssignedRecords().subscribe(
      (response) => {
        this.coolerAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPosmEngagmentBulkAssignedData(): void {
    this.teamLeaderService.PosmEngagementBulkAssignedRecords().subscribe(
      (response) => {
        this.posmEngagementAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getContestBulkAssignedData(): void {
    this.teamLeaderService.contestBulkAssignedRecords().subscribe(
      (response) => {
        this.contestAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getMysteryBulkAssignedData(): void {
    this.teamLeaderService.MysteryBulkAssignedRecords().subscribe(
      (response) => {
        this.mysteryAssignedCounts = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getPictureTaskRecords(): void {
    this.teamLeaderService.TaskDetailsRecords().subscribe(
      (response) => {
        this.pictureTaskRecords = response;

        if (this.pictureTaskRecords.length > 0) {
          this.enagementRecords = this.pictureTaskRecords;
          this.pictureTaskRecords.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "Not Assigned";
            } else if (p.internal_status == 1) {
              p.statusString = "Assigned";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }

            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }

            if (p.reason == 1) {
              p.rejectedReasonString = "Invalid bill";
            }

            if (p.reason == 2) {
              p.rejectedReasonString = "Poor image quality of the bill";
            }

            if (p.reason == 3) {
              p.rejectedReasonString = "Invalid bill type";
            }

            if (p.reason == 4) {
              p.rejectedReasonString = "Product not found on the bill";
            }

            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }

            p.engagment_name = p.title;
          });

          this.pictureTaskRecordsUnassignedList = this.pictureTaskRecords.filter(
            (p) => p.internal_status == 0
          );
          this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecords.filter(
            (p) => p.internal_status == 1
          );
          this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecords.filter(
            (p) => p.internal_status == 2
          );
          this.pictureTaskRecordsRejectedList = this.pictureTaskRecords.filter(
            (p) => p.internal_status == 3
          );
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  BulkAssignToAgent(category: any, assignmentData: any, count): void {
    debugger;
    this.teamLeaderService
      .BulkAssignedRecords(assignmentData, category)
      .subscribe(
        (response: any) => {
          if (response) {
            this.toastr.success(
              `${count} Records Assigned Successfully`,
              "Success"
            );
          }
          if (category == 1) {
            this.getPosmBulkAssignedData();
            this.posmAuditTransaction = response;
            if (this.posmAuditTransaction.length > 0) {
              this.posmAuditTransaction.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                p.images = [];

                if (p.image_url != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.image_url;
                  p.images.push(imageData);
                }

                if (p.denominator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.denominator_image;
                  p.images.push(imageData);
                }

                if (p.numerator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.numerator_image;
                  p.images.push(imageData);
                }

                if (p.xray_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.xray_image;
                  p.images.push(imageData);
                }

                if (p.posm_ml_output_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.posm_ml_output_image;
                  p.images.push(imageData);
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid bill";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the bill";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }
              });
              this.posmAuditTransaction = this.posmAuditTransaction.filter(
                (p) =>
                  p.posm_type == 2 && p.campaign_id == null && p.task_id == null
              );
              this.posmAuditUnassignedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 3 &&
                  p.internal_status == 0 &&
                  p.posm_type == 2 &&
                  p.campaign_id == null &&
                  p.task_id == null
              );
              this.posmAuditAssignedPendingList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 3 &&
                  p.internal_status == 1 &&
                  p.posm_type == 2 &&
                  p.campaign_id == null &&
                  p.task_id == null
              );
              this.posmAuditAssignedProcessedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 1 &&
                  p.internal_status == 2 &&
                  p.posm_type == 2 &&
                  p.campaign_id == null &&
                  p.task_id == null
              );
              this.posmAuditRejectedList = this.posmAuditTransaction.filter(
                (p) =>
                  p.status == 2 &&
                  p.internal_status == 3 &&
                  p.posm_type == 2 &&
                  p.campaign_id == null &&
                  p.task_id == null
              );
            }
          }
          if (category == 6) {
            this.getPosmCoolerBulkAssignedData();
            this.coolerAuditTransaction = response;
            if (this.coolerAuditTransaction.length > 0) {
              this.posmAuditTransaction.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                p.images = [];

                if (p.image_url != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.image_url;
                  p.images.push(imageData);
                }

                if (p.denominator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.denominator_image;
                  p.images.push(imageData);
                }

                if (p.numerator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.numerator_image;
                  p.images.push(imageData);
                }

                if (p.xray_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.xray_image;
                  p.images.push(imageData);
                }

                if (p.posm_ml_output_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.posm_ml_output_image;
                  p.images.push(imageData);
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid bill";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the bill";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }

                p.checked = false;
              });

              this.coolerAuditTransaction = this.coolerAuditTransaction.filter(
                (p) => p.posm_type == 3
              );
              this.coolerAuditUnassignedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 0 && p.posm_type == 3
              );
              this.coolerAuditAssignedPendingList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 3 && p.internal_status == 1 && p.posm_type == 3
              );
              this.coolerAuditAssignedProcessedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 1 && p.internal_status == 2 && p.posm_type == 3
              );
              this.coolerAuditRejectedList = this.coolerAuditTransaction.filter(
                (p) =>
                  p.status == 2 && p.internal_status == 3 && p.posm_type == 3
              );
              this.coolerAuditAssignedPendingList.forEach((p) => {
                p.checked = false;
              });
            }
          } else if (category == 2) {
            this.getContestBulkAssignedData();
            this.weekEntries = response;
            if (this.weekEntries.length) {
              this.weekEntries.forEach((e) => {
                e.isConfirmed = false;
                e.isShortlisted = false;
                e.isRejected = false;
                e.notShortlist = false;
                if (e.moderator_internal_status == 9) {
                  e.isConfirmed = true;
                }

                if (e.moderator_internal_status == 4) {
                  e.isShortlisted = true;
                }

                if (e.status == 2) {
                  e.isHolded = true;
                }

                if (e.moderator_internal_status == 6) {
                  e.isRejected = true;
                }

                e.isPDF = false;

                if (e.data_path) {
                  let path = e.data_path.split(".");

                  if (path[path.length - 1] == "pdf") {
                    e.isPDF = true;
                  }
                }

                let sumRating = 0;
                e.rating.forEach((r) => {
                  sumRating += r.rating;
                });
                e.ratingvalue = sumRating;
              });

              this.WeekEntriesUnassignedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 0
              );
              this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 2
              );
              this.WeekEntriesRejectedList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 3
              );
              this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
                (w) => w.moderator_internal_status == 1
              );
            }
          } else if (category == 5) {
            this.getMysteryBulkAssignedData();
            this.mysteryShopperRecords = response;
            if (this.mysteryShopperRecords.length > 0) {
              this.mysteryShopperRecords.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }
                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }
              });

              this.mysteryShopperUnassignedList = this.mysteryShopperRecords.filter(
                (p) => p.status == 0 && p.internal_status == 0
              );
              this.mysteryShopperAssignedPendingList = this.mysteryShopperRecords.filter(
                (p) => p.status == 0 && p.internal_status == 1
              );
              this.mysteryShopperAssignedProcessedList = this.mysteryShopperRecords.filter(
                (p) => p.status == 1 && p.internal_status == 2
              );
              this.mysteryShopperRejectedList = this.mysteryShopperRecords.filter(
                (p) => p.status == 0 && p.internal_status == 3
              );
            }
          } else if (category == 7) {
            this.getPosmEngagmentBulkAssignedData();
            this.engagementposmAuditTransaction = response;
            if (this.engagementposmAuditTransaction.length > 0) {
              this.engagementposmAuditTransaction.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                p.images = [];

                if (p.image_url != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.image_url;
                  p.images.push(imageData);
                }

                if (p.denominator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.denominator_image;
                  p.images.push(imageData);
                }

                if (p.numerator_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.numerator_image;
                  p.images.push(imageData);
                }

                if (p.xray_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.xray_image;
                  p.images.push(imageData);
                }

                if (p.posm_ml_output_image != null) {
                  let imageData: imageModel = new imageModel();
                  imageData.image = p.posm_ml_output_image;
                  p.images.push(imageData);
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid bill";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the bill";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }
              });

              this.engagementposmAuditTransaction = this.engagementposmAuditTransaction.filter(
                (p) =>
                  p.posm_type == 2 && p.campaign_id != null && p.task_id != null
              );
              this.totalEnagement = this.engagementposmAuditTransaction;
              this.engagementposmAuditUnassignedList = this.engagementposmAuditTransaction.filter(
                (p) =>
                  p.status == 3 &&
                  p.internal_status == 0 &&
                  p.posm_type == 2 &&
                  p.campaign_id != null &&
                  p.task_id != null
              );
              this.engagementposmAuditAssignedPendingList = this.engagementposmAuditTransaction.filter(
                (p) =>
                  p.status == 3 &&
                  p.internal_status == 1 &&
                  p.posm_type == 2 &&
                  p.campaign_id != null &&
                  p.task_id != null
              );
              this.engagementposmAuditAssignedProcessedList = this.engagementposmAuditTransaction.filter(
                (p) =>
                  p.status == 1 &&
                  p.internal_status == 2 &&
                  p.posm_type == 2 &&
                  p.campaign_id != null &&
                  p.task_id != null
              );
              this.engagementposmAuditRejectedList = this.engagementposmAuditTransaction.filter(
                (p) =>
                  p.status == 2 &&
                  p.internal_status == 3 &&
                  p.posm_type == 2 &&
                  p.campaign_id != null &&
                  p.task_id != null
              );
            }
          } else if (category == 8) {
            this.getPictureBulkAssignedData();
            this.pictureTaskRecords = response;
            if (this.pictureTaskRecords.length > 0) {
              this.pictureTaskRecords.forEach((p) => {
                if (p.internal_status == 0) {
                  p.statusString = "Not Assigned";
                } else if (p.internal_status == 1) {
                  p.statusString = "Assigned";
                } else if (p.internal_status == 2) {
                  p.statusString = "Approved";
                } else if (p.internal_status == 3) {
                  p.statusString = "Rejected";
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid bill";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the bill";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }

                if (p.agent_comments == null) {
                  p.agent_comments = "--Not Added--";
                }

                if (p.reason == 1) {
                  p.rejectedReasonString = "Invalid bill";
                }

                if (p.reason == 2) {
                  p.rejectedReasonString = "Poor image quality of the bill";
                }

                if (p.reason == 3) {
                  p.rejectedReasonString = "Invalid bill type";
                }

                if (p.reason == 4) {
                  p.rejectedReasonString = "Product not found on the bill";
                }
              });

              this.pictureTaskRecordsUnassignedList = this.pictureTaskRecords.filter(
                (p) => p.internal_status == 0
              );
              this.pictureTaskRecordsAssignedPendingList = this.pictureTaskRecords.filter(
                (p) => p.internal_status == 1
              );
              this.pictureTaskRecordsAssignedProcessedList = this.pictureTaskRecords.filter(
                (p) => p.internal_status == 2
              );
              this.pictureTaskRecordsRejectedList = this.pictureTaskRecords.filter(
                (p) => p.internal_status == 3
              );
            }
          }
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  getInvoicesRecords(): void {
    this.teamLeaderService.getInvoiceRecordsData().subscribe((response) => {
      if (response) {
        this.invoiceRecords = response;
      }

      if (this.invoiceRecords.length > 0) {
        this.invoiceRecordsAssignedPendingList = this.invoiceRecords.filter(
          (i) => i.internal_status == 1
        );
        this.invoiceRecordsAssignedProcessedList = this.invoiceRecords.filter(
          (i) => i.internal_status == 2
        );
        this.invoiceRecordsUnassignedList = this.invoiceRecords.filter(
          (i) => i.internal_status == 0
        );
        this.invoiceRecordsRejectedList = this.invoiceRecords.filter(
          (i) => i.internal_status == 3
        );
      }
    });
  }

  getContestEntries(): void {
    this.teamLeaderService.getImageContestData().subscribe((response) => {
      if (response) this.weekEntries = response;
      if (this.weekEntries.length) {
        this.weekEntries.forEach((e) => {
          e.isConfirmed = false;
          e.isShortlisted = false;
          e.isRejected = false;
          e.notShortlist = false;
          if (e.moderator_internal_status == 9) {
            e.isConfirmed = true;
          }

          if (e.moderator_internal_status == 4) {
            e.isShortlisted = true;
          }

          if (e.status == 2) {
            e.isHolded = true;
          }

          if (e.moderator_internal_status == 6) {
            e.isRejected = true;
          }

          e.isPDF = false;

          if (e.data_path) {
            let path = e.data_path.split(".");

            if (path[path.length - 1] == "pdf") {
              e.isPDF = true;
            }
          }

          let sumRating = 0;
          e.rating.forEach((r) => {
            sumRating += r.rating;
          });
          e.ratingvalue = sumRating;
        });

        this.WeekEntriesUnassignedList = this.weekEntries.filter(
          (w) => w.moderator_internal_status == 0
        );
        this.WeekEntriesAssignedProcessedList = this.weekEntries.filter(
          (w) => w.moderator_internal_status == 9
        );
        this.WeekEntriesRejectedList = this.weekEntries.filter(
          (w) => w.moderator_internal_status == 6
        );
        this.WeekEntriesAssignedPendingList = this.weekEntries.filter(
          (w) => w.moderator_internal_status == 3
        );
      }
    });
  }

  getMysteryShopperRecords(): void {
    this.teamLeaderService.getMysteryShopperRecords().subscribe((response) => {
      if (response) {
        this.mysteryShopperRecords = response;
        if (this.mysteryShopperRecords.length > 0) {
          this.mysteryShopperRecords.forEach((p) => {
            if (p.internal_status == 0) {
              p.statusString = "Not Assigned";
            } else if (p.internal_status == 1) {
              p.statusString = "Assigned";
            } else if (p.internal_status == 2) {
              p.statusString = "Approved";
            } else if (p.internal_status == 3) {
              p.statusString = "Rejected";
            }

            if (p.agent_comments == null) {
              p.agent_comments = "--Not Added--";
            }
          });

          this.mysteryShopperUnassignedList = this.mysteryShopperRecords.filter(
            (p) => p.status == 0 && p.internal_status == 0
          );
          this.mysteryShopperAssignedPendingList = this.mysteryShopperRecords.filter(
            (p) => p.status == 0 && p.internal_status == 1
          );
          this.mysteryShopperAssignedProcessedList = this.mysteryShopperRecords.filter(
            (p) => p.status == 1 && p.internal_status == 2
          );
          this.mysteryShopperRejectedList = this.mysteryShopperRecords.filter(
            (p) => p.status == 0 && p.internal_status == 3
          );
        }
      }
    });
  }

  getAllPosmConfigList(): void {
    this.teamLeaderService.PosmConfigList().subscribe(
      (response) => {
        this.posmConfigList = response;
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }

  getAllPosmAuditList(): void {
    this.teamLeaderService.PosmAuditList().subscribe(
      (response) => {
        this.posmAuditList = response;
        if (this.posmAuditList.length > 0) {
          this.posmAuditList.forEach((p) => {
            p.posm_audit_type_name =
              p.posm_audit_type_name + " - " + p.channel_desc;
          });
        }
      },
      (error) => {
        this.alertService.error(error);
      }
    );
  }
}
