import { Injectable } from '@angular/core';
import { GlobalService } from './SiteAuth/global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoginForm } from '../models/loginForm';
import { Users } from '../models/UserModel';

// User Login Related API's

const LOGIN_API = '/admin/login';
const USERS_API = '/admin/getusers';

@Injectable({
  providedIn: 'root'
})

export class UserServiceService {

  constructor(private http: HttpClient, private siteAuth: GlobalService) {
  }

  private getHeaders() {

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    });
  }

  userLogin(LoginForm: LoginForm): Observable<any> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + LOGIN_API;
    let body = JSON.stringify({ LoginForm });
    return this.http.post<any>(apiUrl, body, { headers: headers })
      .pipe(
        map(
          response => {
            return response;
          }
        ),
        catchError(this.handleError)
      )
  }


  getUsers(): Observable<Users[]> {
    let headers = this.getHeaders();
    let apiUrl = this.siteAuth.apiHostUrl + USERS_API;
    return this.http.get<any>(apiUrl, { headers: headers })
      .pipe(
          map(
            response => {
              return <Users[]>response.data;
            }
          ),
        catchError(this.handleError)
      )
  }

  private handleError(response: any) {

    let errorMessage: any = {};
    // Connection error
    console.log(response);

    if (response.status === 0) {
      errorMessage = {
        success: false,
        status: 0,
        data: 'Sorry, there was a connection error occurred. Please try again.'
      };
    } else {
      errorMessage = response.error;
    }

    return throwError(errorMessage);
  }
}
