import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AgentsRoutingModule } from './agents-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { AgentsComponent } from './agents.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AgentDataProvider } from './Providers/AgentDataProvider';
import { AlertService } from '../Providers/alertProvider';
import { AgentProvider } from './Providers/AgentProvider';
import { ProgramDataService } from '../Service/program-data.service';
import { ToastrModule } from 'ngx-toastr';
import { WeekEntriesProvider } from './Providers/WeekEntriesProvider';
import { NotfoundComponent } from '../notfound/notfound.component';
import { ExportExcelService } from './Service/export-excel.service';


@NgModule({
  declarations: [AgentsComponent, NotfoundComponent],
  imports: [
    CommonModule,
    AgentsRoutingModule,
    LayoutModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true
    }),
  ],
  providers: [
    AgentDataProvider,
    AlertService,
    AgentProvider,
    ProgramDataService,
    WeekEntriesProvider,
    ExportExcelService,
    DatePipe
  ]
})
export class AgentsModule { }
