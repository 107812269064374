import { Injectable } from '@angular/core';
import { AreaDataProvider } from '../../Providers/AreaDataProvider';
import { TeamLeaderProvider } from './TeamLeaderProvider';
import { RouteDataProvider } from '../../Shared/RouteDataProvider.provider';
import { EventType, EventData } from '../../models/sharedModel';

@Injectable()

export class TeamLeaderDataProvider extends AreaDataProvider {

    constructor(public routerDataProvider: RouteDataProvider,
        public teamLeaderProvider: TeamLeaderProvider
    ) {
        super('Entries', routerDataProvider)
    }

    areaChanged(newArea: string, firstCall: boolean) {
        if (firstCall) {
            return;
        }
    }

    subAreaChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaChanged });
    }

    queryParamChanged(newQueryParams: any, firstCall: boolean) {
        if (firstCall) {
            return;
        }
        if (newQueryParams.id !== undefined) {
            this.eventHandler({ type: EventType.queryParamChanged });
        } else {
            this.eventHandler({ type: EventType.areaChanged });
        }
    }

    subAreaInsideChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaInsideChanged });
    }

    subAreaInsideAreaChanged(newSubArea: string, firstCall: boolean) {
        if (firstCall || newSubArea == this.currentAreaName) {
            return;
        }
        this.eventHandler({ type: EventType.subAreaInsideAreaChanged });
    }

    urlMaker(data: any): string {
        return "";
    }

    eventHandler(eventData: EventData) {
        switch (eventData.type) {
            case EventType.queryParamChanged:
                console.log('Query Param Changed', this.currentQueryParam);
                break;
            case EventType.areaChanged:
                console.log('Query Param Changed', this.currentAreaName);
                switch (this.currentAreaName) {
                    case "team-leader":
                        console.log('I am inside Team Leader');
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getAllAgents();
                        this.teamLeaderProvider.getContestEntries();
                        this.teamLeaderProvider.getInvoicesRecords();
                        this.teamLeaderProvider.getAllCampaignData();
                        this.teamLeaderProvider.getAllCoolerPosmAuditTransactionData();
                        this.teamLeaderProvider.getAllEngagementPosmAuditTransactionData();
                        break;
                }
                 break;
            case EventType.subAreaChanged:
                console.log('Sub Area Changed', this.currentSubAreaName);
                break;
            case EventType.subAreaInsideChanged:
                console.log('Sub Inside Area Changed', this.currentSubInsideAreaName);
                switch (this.currentSubInsideAreaName) {
                    case "posm":
                        console.log('I am inside Team Leader');
                        this.teamLeaderProvider.getAllPosmConfigList();
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getPosmBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        this.teamLeaderProvider.getAllPosmAuditList();
                        break;
                    case "cooler":
                        console.log('I am inside Cooler Leader');
                        this.teamLeaderProvider.getAllPosmConfigList();
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getPosmCoolerBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        this.teamLeaderProvider.getAllPosmAuditList();
                        this.teamLeaderProvider.getAllCoolerPosmAuditTransactionData();
                        break;
                    case "contest":
                        this.teamLeaderProvider.getContestEntries();
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getContestBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        break;
                    case "invoice":
                        this.teamLeaderProvider.getInvoicesRecords();
                        this.teamLeaderProvider.getAllProgamData();
                        break;
                    case "mystery":
                        console.log('I am inside Team Leader');
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getMysteryShopperRecords();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getMysteryBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        break;
                    case "posm-reassign":
                        console.log('I am inside posm reassign');
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllAgents();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                }
                break;
            case EventType.subAreaInsideAreaChanged:
                console.log('Sub Inside Area Inside Changed', this.currentSubInsideAreaInsideName);
                switch (this.currentSubInsideAreaInsideName) {
                    case "posm":
                        console.log('I am inside Team Leader of POSM Engagement');
                        this.teamLeaderProvider.getAllPosmConfigList();
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getPosmEngagmentBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        this.teamLeaderProvider.getAllPosmAuditList();
                        this.teamLeaderProvider.getAllEngagementPosmAuditTransactionData();
                        break;
                    case "picture":
                        console.log('I am inside Team Leader of Picture Engagement');
                        this.teamLeaderProvider.getAllPosmConfigList();
                        this.teamLeaderProvider.getAllProgamData();
                        this.teamLeaderProvider.getAllPosmAuditTransactionData();
                        this.teamLeaderProvider.getPictureBulkAssignedData();
                        this.teamLeaderProvider.getAllCampaignData();
                        this.teamLeaderProvider.getPictureTaskRecords();
                        break;
                }
        }
    }

}
