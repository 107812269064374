import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProgramModel, CampaignModel } from 'src/app/modules/models/ProgramModel';
import { Router } from '@angular/router';
import { TeamLeaderProvider } from '../../Providers/TeamLeaderProvider';
import { UserDataProvider } from 'src/app/modules/Shared/UserDataProvider';
import { RouteDataProvider } from 'src/app/modules/Shared/RouteDataProvider.provider';
import { TeamLeaderDataProvider } from '../../Providers/TeamLeaderDataProvider';
import { PosmAuditTransactionsModel } from '../../Models/PosmAuditTransactionsModel';
import { WeekEnttries } from '../../Models/WeekEntries';
import { DatePipe } from '@angular/common';
import { InvoiceModel } from 'src/app/modules/agents/Models/Invoice';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  programList: ProgramModel[];
  disabled: boolean = false;
  campaignDisabled: boolean = false;
  posmAuditTransactions: PosmAuditTransactionsModel[];
  coolerAuditTransactions : PosmAuditTransactionsModel[];
  programId: number;
  campaignId: number;
  _errorMessage;
  posmAuditUnassignedList: PosmAuditTransactionsModel[];
  WeekEntries: WeekEnttries[];
  invoiceRecords: InvoiceModel[];
  campaignList: CampaignModel[];
  selectedProgram: number;
  searchRange: Date;
  minDate: Date;
  maxDate: Date;
  date1: string;
  date2: string;
  programCampaign: CampaignModel[];
  engagements : any[];
  engagements1 : any[];
  _campaignId;
  _startdate;
  _enddate;
  constructor(public ngxLoader: NgxUiLoaderService,
    public router: Router,
    public teamLeaderProvider: TeamLeaderProvider,
    public teamLeaderDataProvider: TeamLeaderDataProvider,
    public routerDataProviders: RouteDataProvider,
    public datepipe: DatePipe,
  ) {
    console.log('dashboard loading....')
  }

  ngOnInit() {

  }

  ngDoCheck() {
    this.programList = this.teamLeaderProvider.programList;
    this.campaignList = this.teamLeaderProvider.CampaignList;
    this.posmAuditTransactions = this.teamLeaderProvider.posmAuditTransaction;
    this.coolerAuditTransactions = this.teamLeaderProvider.coolerAuditTransaction;
    this.WeekEntries = this.teamLeaderProvider.weekEntries;
    this.invoiceRecords = this.teamLeaderProvider.invoiceRecords;
    this.engagements    = this.teamLeaderProvider.enagementRecords;
    this.engagements1    = this.teamLeaderProvider.enagementRecords1;
    if (typeof (this.programList) == "undefined"
      || typeof (this.campaignList) == "undefined"
      || typeof (this.posmAuditTransactions) == "undefined"
      || typeof (this.WeekEntries) == "undefined"
      || typeof (this.invoiceRecords) == "undefined") {
      this.ngxLoader.start();
      setTimeout(() => {
        this.ngxLoader.stop(); // stop foreground spinner of the master loader with 'default' taskId
      }, 1000);
      this.programList = [];
      this.campaignList = [];
      this.posmAuditTransactions = [];
      this.WeekEntries = [];
      this.invoiceRecords = [];
      this.engagements = [];
      this.engagements1 = [];
    }


  }

  onChangeProgram(item) {
    this.selectedProgram = parseInt(item);
    this.disabled = true;
    if (!Number.isNaN(this.selectedProgram)) {
      this.campaignDisabled = true;
      this.programCampaign = this.campaignList.filter(c => c.program_id == this.selectedProgram);
    }
    else {
      this.campaignDisabled = false;
      this.teamLeaderProvider.getAllPosmAuditTransactionData();
    }
    this.programId = this.selectedProgram;
  }

  onChangeCampaign(item) {
    this.campaignId = (item);
  }

  onSearch() {

    console.log(this.searchRange);
    if (typeof this.searchRange != "undefined") {
      this.date1 = this.datepipe.transform(this.searchRange[0], 'yyyy-MM-dd');
      this.date2 = this.datepipe.transform(this.searchRange[1], 'yyyy-MM-dd');
    }
    this.teamLeaderProvider.getAllPosmAuditTransactionData();
    if (!Number.isNaN(this.selectedProgram) && typeof (this.campaignId) != "undefined" && this.campaignId != 0) {
      this.teamLeaderProvider.getAllProgamCampaignPOSMWithOutDate(this.selectedProgram, this.campaignId)
    }
    if (!Number.isNaN(this.selectedProgram) && (typeof (this.campaignId) == "undefined" || this.campaignId == 0) && typeof (this.searchRange) != "undefined") {
      this.teamLeaderProvider.getAllProgamPOSMDate(this.selectedProgram, this.date1, this.date2)
    }
    else if (!Number.isNaN(this.selectedProgram)) {
      this.teamLeaderProvider.getAllProgamPOSM(this.selectedProgram);
    }
    else {
      this.teamLeaderProvider.getAllPosmAuditTransactionData();
    }
  }
}
