export class PosmAuditTransactionsModel {
    id: number;
    program_id: number;
    campaign_id:number;
    user_id: number;
    user_name: string;
    lat: string;
    lng: string;
    posm_type:number;
    posm_type_name:string;
    posm_audit_typeid: number;
    posm_audit_type_name: string;
    image_url:string;
    points: number;
    created_date: string;
    status: number;
    updated_date: null;
    user_detail_id: number;
    user_detail_name: string;
    program_name: string;
    statusString : string;
    internal_status : number;
    agent_comments : string;
    agent_comments_date : string;
    reason : number;
    resonString: string;
    assigned_user_name : string;
    assigned_date : string;
    assigned_user_id : number;
    checked:boolean;
    posm_approved_image : string;
    assigned_id : number;
    task_id : number;
    channel_desc : string;
    rejectedReasonString : string;
    ro_code : string;
    title : string;
    mobile : number;
    channel_id : number;
    ml_computation_flag : number;
    purity_score : string;
    numerator_image : string;
    denominator_image : string;
    xray_image : string;
    ml_processed_date : string;
    ml_processed_json : string;
    evaluated_purity_score: string;
    mlStatusString : string;
    reasonString : string;
    agentStatusString : string;
    posm_ml_output_image : string;
    images : imageModel[];
    isCooler:boolean;
    comment_selected : number;
}

export class PosmBulkAssignModel {
    program_id : number;
    itemRows : any;
}

export class imageModel {
  image: any;
}
